import React, {useEffect, useState, useRef, Fragment} from 'react'
import Layout from '../../components/layout'
import Validate from '../../components/validate'
import Nav from '../../components/ui/nav'
import { navigate } from 'gatsby'
import ComboBox from 'react-responsive-combo-box'
import Footer from '../../components/landing/footer'
import DownloadLink from '../../components/ui/downloadlink'
import ContactBox from '../../components/ui/contact'

// Get the URLs depending on our environment
let redirectUri = 'http://localhost:8888/oauth-callback'
let lambdaUri = 'http://localhost:8888'

const currentEnv = process.env.GATSBY_ENV
if (currentEnv === "prod"){
  redirectUri = 'https://www.paverops.com/oauth-callback'
  lambdaUri = 'https://www.paverops.com'
}
if (currentEnv === "stage"){
  redirectUri = 'https://stage--paverops.netlify.app/oauth-callback'
  lambdaUri = `https://stage--paverops.netlify.app`
}

// markup
const Downloads = () => {
  return (
    <Layout>
      <main>
        <Validate lambdaUri={lambdaUri} redirectUri={redirectUri}>
          <div className="non-landing white">
            <Nav page={"support"} items={[
              {url: "/support/", text: "Support Hub"},
              {url: "/app/", style: "border", text: "App Home"}
            ]} />
            <div className="topper">
              <div className="img-wrapper">
                <img src="/images/toppersupport.png" alt="An angular slice of a road being repaired with heavy machinery" />
              </div>
              <div className="deck-wrapper">
                <div className="deck">
                  <h1>
                    Downloads<br />
                    & Resources
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <section className="app-body support help">
            <div className="downloads-container">
              <h2>Template Downloads</h2>
              <p>Use these templates to prepare data in the PaverOps data model. Preprocessing your organization's data using the PaverOps layer schema will help make uploads a breeze and simplify automated data processing for future uploads. These templates can even be used to create your PaverOps data from the ground up. This is especially valuable for organizations with a budding GIS program.</p>
              <div className="resource-wrapper">
                <div className="resource-block">
                  <h3>File Geodatabases</h3>
                  <ul>
                    <li>
                      <DownloadLink 
                        url="/downloads/FileGeodatabases/PaverOps_Paving_DataTemplates.gdb.zip"
                        icon="downloads"
                        text="Paving Data Template"
                      />
                    </li>
                    <li>
                      <DownloadLink 
                        url="/downloads/FileGeodatabases/PaverOps_UtilityLayers_DataTemplates.gdb.zip"
                        icon="downloads"
                        text="Utility Layers Template"
                      />
                    </li>
                    <li>
                      <DownloadLink 
                        url="/downloads/FileGeodatabases/PaverOps_Project_DataTemplate.gdb.zip"
                        icon="downloads"
                        text="Project Template"
                      />
                    </li>
                    <li>
                      <DownloadLink 
                        url="/downloads/FileGeodatabases/PaverOpsMemberServiceArea_FGDDataTemplate.gdb.zip"
                        icon="downloads"
                        text="Member Service Area"
                      />
                    </li>
                    <li>
                      <DownloadLink 
                        url="/downloads/FileGeodatabases/PaverOpsOrganizationServiceBoundary_FGDDataTemplate.gdb.zip"
                        icon="downloads"
                        text="Organization Service Boundary"
                      />
                    </li>
                  </ul>
                </div>
                <div className="resource-block">
                  <h3>Shapefiles</h3>
                  <ul>
                    <li>
                      <DownloadLink 
                        url="/downloads/Shapefiles/PaverOps_Paving_ShapefileDataTemplates.zip"
                        icon="downloads"
                        text="Paving Data Template"
                      />
                    </li>
                    <li>
                      <DownloadLink 
                        url="/downloads/Shapefiles/PaverOps_Utilities_ShapefileDataTemplate.zip"
                        icon="downloads"
                        text="Utility Layers Template"
                      />
                    </li>
                    <li>
                      <DownloadLink 
                        url="/downloads/Shapefiles/PaverOps_Projects_ShapefileDataTemplate.zip"
                        icon="downloads"
                        text="Project Template"
                      />
                    </li>
                    <li>
                      <DownloadLink 
                        url="/downloads/Shapefiles/PaverOpsMemberServiceArea_ShapefileTemplate.zip"
                        icon="downloads"
                        text="Member Service Area"
                      />
                    </li>
                    <li>
                      <DownloadLink 
                        url="/downloads/Shapefiles/PaverOpsOrganizationServiceBoundary_ShapefileTemplate.zip"
                        icon="downloads"
                        text="Organization Service Boundary"
                      />
                    </li>
                  </ul>
                </div>
                <div className="resource-block">
                  <h3>Schema XML</h3>
                  <ul>
                    <li>
                      <DownloadLink 
                        url="/downloads/SchemaXMLs/PaverOps_Paving_XMLSchemaTemplates.xml"
                        icon="downloads"
                        text="Paving Plan Template"
                      />
                    </li>
                    <li>
                      <DownloadLink 
                        url="/downloads/SchemaXMLs/PaverOps_Utilities_XMLSchemaTemplates.xml"
                        icon="downloads"
                        text="Utility Layers Template"
                      />
                    </li>
                    <li>
                      <DownloadLink 
                        url="/downloads/SchemaXMLs/PaverOps_Projects_XMLSchemaTemplates.xml"
                        icon="downloads"
                        text="Project Template"
                      />
                    </li>
                    <li>
                      <DownloadLink 
                        url="/downloads/SchemaXMLs/PaverOpsMemberServiceArea_XMLTemplate.xml"
                        icon="downloads"
                        text="Member Service Area"
                      />
                    </li>
                    <li>
                      <DownloadLink 
                        url="/downloads/SchemaXMLs/PaverOpsOrgServiceBoundary_XMLTemplate.xml"
                        icon="downloads"
                        text="Organization Service Boundary"
                      />
                    </li>
                  </ul>
                </div>
                <div className="resource-block">
                  <h3>Documentation</h3>
                  <ul>
                    <li>
                      <DownloadLink 
                        url="/downloads/PaverOps Data Dictionary for Members.pdf"
                        icon="downloads"
                        text="PaverOps data dictionary for members"
                      />
                    </li>
                  </ul>
                </div>
              </div>
              <ContactBox text="Something else you’d like to see on this page?" />
            </div>
          </section>

          <Footer />
        </Validate>
      </main>
    </Layout>
  )
}

export default Downloads

