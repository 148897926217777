import React, {useState, Fragment} from 'react'
import { chunkOperation } from '../../utils/esri-helpers'

// Separated out the "submit and replace" button because it was getting unwieldy

const DownloadLink = ({icon, url, text}) => {
  return (
    <a href={url}>
      <img src={`/icons/${icon}.svg`} />{text}
    </a>
  )
}

export default DownloadLink